<template>
  <div class="primary-market-project">
    <div class="container-xl">
      <detailed-project :uuid="uuid" :investment="true" :secondary-market="false"/>
    </div>
  </div>
</template>

<script>
import { DetailedProject } from "../../../../common";
import {RulesConfirmedModal} from "@/components/common";

export default {
  name: "PrimaryMarketProject",
  components: {
    DetailedProject,
    RulesConfirmedModal
  },
  inject: ['profile'],
  data() {
    return {
      uuid: this.$route.params.uuid
    };
  }
}
</script>
